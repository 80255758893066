.testimonials-wrapper {
    max-width: 100vw;
    overflow: hidden;
}

.testimonials-row {
    transition: all 0.25s;
    overflow-x: visible;
    overflow-y: hidden;
    max-width: 300%;
    max-height: 550px;
    width: 300%;
}

.testimonial-column {
    overflow-y: hidden;
    overflow-x: hidden;
    transition: all 0.5s;
    max-width: 1525px;
}

.testimonial-card img {
    object-fit: cover;
    height: 400px;
}

.testimonial-card .img-left {
    border-radius: 25px 0px 0px 25px;
}

.testimonial-card .img-right {
    border-radius: 0px 25px 25px 0px;
}

#testimonials .btn-next {    
    border-radius: 25px;
    position: absolute;
    right: -16px;
    top: 45%;
}

#testimonials .btn-prev {
    border-radius: 25px;
    position: absolute;
    left: -16px;
    top: 45%;
}

@media (max-width: 767px) {
    .testimonial-card img {
        height: 350px;
    }
    #testimonials .btn-next {    
        border-radius: 25px;
        position: absolute;
        right: -16px;
        top: 350px;
    }
    #testimonials .btn-prev {
        border-radius: 25px;
        position: absolute;
        left: -16px;
        top: 350px;
    }
}