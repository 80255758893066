.monterrey-locations {
  padding: 80px 97px;
  min-height: 550px;
  width: 100vw;
  overflow-x: auto;
}

.locations__container {
  display: flex;
  justify-content: space-between;
}

.monterrey-location__card {
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  min-height: 390px;
  height: max-content;
  border-radius: 5px !important;
}

.premises-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card__content {
  height: 330px;
}
.card__content img {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.card__footer {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #F9F9F9;
  height: 60px;
}

.accordion, 
.accordion .accordion-button:not(.bg-light),
.accordion .accordion-header,
.accordion .accordion-collapse
{
  background-color: #F9F9F9 !important;
}

.card__footer h2 {
  font-size: 24px ;
  line-height: 24px;
  color: black;
  font-weight: 900;
}

@media(max-width: 767px) {
  .monterrey-locations {
    padding: 30px 20px;
    justify-content: flex-start;
    height: max-content;
  }

  .locations__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .monterrey-location__card {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 40px);
    border-radius: 5px !important;
    margin-bottom: 20px;
  }

}