.embla {
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 2rem;
  --slide-size: 390px;
  --mobile-slide-size: 100%;
}

.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 16px;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: var(--slide-height);
  user-select: none;
  border: 1px solid gray;
  height: 490px;
  padding: 20px;
}

.embla__type-name {
  font-size: 30px;
  color: black;
  font-weight: 900;
  line-height: 30px;
}

.embla__type-image {
  width: 100%;
  border-radius: 16px;
  height: 310px;
  min-height: 310px;
  object-fit: cover;
  margin-bottom: 10px;
}

.embla__type-description {
  font-size: 19px;
  font-stretch: ultra-condensed;
  line-height: 21px;
  font-weight: 500;
  max-height: 105px;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;      
  -webkit-line-clamp: 4;    
  -webkit-box-orient: vertical; 
  white-space: normal;   
}

.embla__controls {
  height: 60px;
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 1.8rem;
}

.embla__buttons {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid gray;
  padding: 0;
  margin: 0;
  margin-right: 5px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
  background-color: var(--primary);
}

.class-types-cta__container {
  padding-right: 85px;
}

@media(max-width: 450px) {
  .embla__slide {
    flex: 0 0 var(--mobile-slide-size);
  }

  .embla__controls {
    display: flex !important;
    margin-bottom: 1.8rem;
    padding: 0;
  }

  .embla__buttons {
    display: none !important;
  }

  .embla__dots {
    width: 100%;
    justify-content: center !important;
  }


  .class-types-cta__container {
    padding-right: 0;
  }
}
