.milestone-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 900px;
    width: auto;
}

.milestone-user-name {
    position: absolute;
    bottom: 280px;
}

.btn-share {
    position: absolute;
    bottom: 80px;
}