.monterrey-schedule__container {
  padding: 0 97px;
  padding-bottom: 20px;
}

@media(max-width: 450px) {
  .monterrey-schedule__container {
    padding: 20px;
  }

}