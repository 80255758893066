.rewards-levels {
  padding-top: 95px;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: 90px;
  width: 100%;
  height: 1030px;
}

.levels-title {
  font-family: 'Futura Std', sans-serif;
  font-size: 39px;
  font-weight: 700;
  line-height: 46.8px;
  text-align: left;
  color: #3A3A33;

}

.levels-description {
  font-family: 'Futura Std', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #666666;
}

.levels-card {
  background: linear-gradient(180deg, rgba(150, 138, 248, 0.8) 65%, #CEDAF0 95%);

}

#rewardsLevelsAccordion {
  padding: 0px !important;
}

.rewards-levels .accordion-item {
/* .rewards-levels .accordion-item .accordion-button */
  background: linear-gradient(180deg, rgba(150, 138, 248, 0.8) 65%, #CEDAF0 95%);
  border-radius: 8px;

}

.rewards-levels .accordion-item-active {
  border-bottom: 8px solid #968AF882
}

.levels__image-desc {
  font-family: 'Futura Std', sans-serif;
font-size: 18px;
font-weight: 400;
line-height: 25px;
text-align: left;
color: #666666;
}

.rewards-levels .accordion-item:has(.accordion-button[aria-expanded="false"]) .accordion-item {
  border-bottom: none !important;
}

.rewards-levels .accordion .accordion-item .accordion-button[aria-expanded="true"],
.rewards-levels .accordion .accordion-header, 
.rewards-levels .accordion .accordion-collapse
{
  background-color: transparent !important;
  box-shadow: none;
}

.rewards-levels .accordion .accordion-item .accordion-button[aria-expanded="true"] {
  color: white !important;
}

.accordion-button[aria-expanded="true"] .item__star {
  background-image: url('../../assets/icons/star.png');
}

.accordion-button[aria-expanded="true"] .star__small {
  background-image: url('../../assets/icons/star-30.svg');
}

.accordion-button[aria-expanded="false"] .item__star {
  background-image: url('../../assets/icons/star-gray.png');
}

.accordion-button[aria-expanded="false"] .star__small {
  background-image: url('../../assets/icons/star-gray-30.png');
}

.rewards-levels .accordion-button[aria-expanded="false"] {
  background-color: #F0F0F0;
  color: #A9A9A9 !important;
}

.rewards-levels .accordion-body  {
  padding-top: 0;
}

.rewards-levels .accordion-body p {
  font-family: 'Futura Std';
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  color: white;
}

.rewards-levels .accordion-body p span {
  font-family: 'Futura Std';
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: white;
}

.rewards-levels .item__feature {
  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-align: left;
  color: white;
  display: inline;

}

.rewards-levels .accordion-item .item__title{
  font-family: 'Futura Std', sans-serif;
  font-size: 30px;
  font-style: italic;
  font-weight: 650;
  line-height: 36px;
  text-align: left;
  /* color: white; */

}

@media(max-width: 767px) {
  .rewards-levels {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
    width: 100%;
    min-height: 1450px;
    height: auto;
  }

.rewards-levels .accordion-item .item__title{
  font-size: 17px;
}

.rewards-levels .item__feature {
  font-size: 10px;
}
  
}