.monterrey-footer {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 350px;
}

.footer__top {
  height: 90px;
  padding: 0 97px;
  background-color: #2B2B2B;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-bottom: 2px solid gray;
}

.footer__top i {
  font-size: 24px;
  margin-left: 25px;
}

.footer__top .fa-instagram {
  margin-left: 0px;
}

.footer__info {
  padding: 0 97px;
  flex: 1;
  background-color: #121110;
}

.footer__info i {
  margin-top: 40px;
  font-size: 40px;
  color: var(--primary);
}

.info__title {
  font-size: 21px;
  color: white;
  font-weight: 500;
  margin-bottom: 10px !important;
  margin-top: 20px;
}

.info__description {
  font-size: 16px;
  color: white;
  font-weight: 400;
}

.footer__bottom {
  font-size: 12px !important;
  padding: 0 97px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__bottom p {
  font-size: 12px;
  margin: 0;
  line-height: 12px;
  height: max-content;

}

.footer__bottom a {
  line-height: 12px;
  color: white;
  height: max-content;
  text-decoration: none !important;
}


@media(max-width: 767px) {
  .monterrey-footer {
    height: max-content;
  }

  .footer__top {
    flex-direction: column;
    padding: 16px 30px;
    height: 140px;
  }

  .footer__top h2{
    font-size: 35px;
  }

  .footer__info {
    flex-direction: column;
    padding: 0 65px;
  }

  .footer__bottom {
    height: 64px;
    padding: 10px 0;
    flex-direction: column;
  }

}