.monterrey-coaches-wrapper {
  width: 100%;
}

.monterrey__coaches-row {
  padding-left: 97px !important; 
  overflow: hidden;
  height: 590px;
}

@media(max-width: 450px) {
  .monterrey-coaches-wrapper {
    padding: 0 9px;
  }

  .monterrey__coaches-row {
    padding: 0 !important; 
    width: 100%;
    margin: auto;
    height: 580px;
    overflow: hidden;
  }
}