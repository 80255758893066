.monterrey-landing-class-types {
  padding-left: 97px;
  padding-bottom: 20px;
}

.monterrey-landing-class-types__title {
  color: black;
  font-size: 40px;
  font-weight: 900;
  line-height: 40px;
  margin-bottom: 10px !important;
}

@media(max-width: 450px) {
  .monterrey-landing-class-types {
    padding: 20px;
    margin-top: 0 !important;
  }

  .monterrey-landing-class-types__title {
    color: black;
    font-size: 34px;
    text-align: center;
    font-weight: 900;
    line-height: 40px;
    margin-bottom: 10px !important;
  }

  .monterrey-landing-class-types__subtitle {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
  }

  .monterrey-landing-class-types__description {
    text-align: center;
  }
}