@media(max-width: 767px) {
  .rewards-navbar {
    z-index: 3;
    width: calc(100% - 30px) !important;
    position: absolute;
  }
}



#rewardsModal .modal-content {
  width: 1040px;
  height: 725px;
  margin: 0 auto;
  margin-top: calc((100vh - 725px) / 2);
  border-radius: 8px;
  border: 1px;
  opacity: 0px;
}

#rewardsModal .title {
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 20.83px;
  letter-spacing: 0.18em;
  text-align: center;
  text-decoration-skip-ink: none;
  background-color: #E9DDFF;
  color: #7550FE;
  width: max-content;
}

#rewardsModal .instagram {
font-size: 16px;
font-weight: 400;
line-height: 20.83px;
letter-spacing: -0.02em;
text-align: left;
text-decoration-skip-ink: none;
color: #90909099;

}

#rewardsModal .instagram i {
  color: black;
}

#rewardsModal .level {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.23px;
  letter-spacing: -0.02em;
  text-align: left;
  text-decoration-skip-ink: none;
  color: rgba(0, 0, 0, 0.6);
}

#rewardsModal .feat {
  color: #7550FE;
  font-size: 16px;
}

#rewardsModal .feat-content {
  font-size: 10px;
  font-weight: 400;
  line-height: 13.02px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}


.item__star {
  background-image: url('../assets/icons/star-gray.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.purple-border-right {
  border-right: 1px solid #7550FE;
}

.gray-border-top {
  border-top: 1px solid #90909099;
  border-bottom: 1px solid #90909099;
}

.gray-border-bottom {
  border-top: 1px solid #90909099;
}