.monterrey__coach-card {
  width: 350px;
  height: 565px;
  background-color: rgba(198, 198, 198, 0.3);
  border: 1px solid gray;
  border-radius: 10px;
  margin-bottom: 20px;
}

.monterrey__coach-img {
  height: 400px;
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  margin: auto;
}

.coach-card__desc {
  border-radius: 10px;
  position: absolute;
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  right: 0;
  margin: auto;
  height: 140px;
  bottom: 0;
}

.coach-quote {
  height: 42px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;      
  -webkit-line-clamp: 2;    
  -webkit-box-orient: vertical; 
  white-space: normal;   
}

@media(max-width: 450px) {
  .monterrey__coach-card {
    width: 100%;
    margin-left: 0 !important;
  }
}