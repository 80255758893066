#accordionExample {
  padding: 0 97px;
  background-color: #ffffff !important;
}

button::after {
  display: none;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  color: black !important;
}

.accordion-button h2 {
  margin-bottom: 0;
  font-size: 24px;
}

.accordion-btn-icon {
  background-color: black;
  color: white;
  padding: 5px;
}

@media(max-width: 450px) {
  #accordionExample {
    padding: 0 20px;
  }

  .accordion {
    padding: 10px 20px;
  }
  
  .accordion-button h2 {
    font-size: 20px;
  }
  
  .accordion-body p {
    font-size: 16px;
  }
}

