.schedule__container {
  border: 1px solid gray;
  border-radius: 16px;
  padding: 30px;
  overflow-x: hidden;
  background-color: #FAFAFA;
  flex-direction: row;
  display: flex;
}

.container__content {
  width: calc(100% - 60px);
  /* min-width: 1090px; */
  overflow-x: auto;
}

.schedule__header {
  min-width: 1090px;
}

.day-type__container {
  width: 130px;
}

.schedule__day-type, .schedule__day {
  font-size: 14px;
}

.schedule__class-hour {
  height: 120px;
  width: 75px;
  font-weight: 600;
  color: black;
}

.class-hour__row {
  border-top: 1px solid gray;
  min-width: 1090px;
}

.class-hour__row .col {
  width: 180px !important;
  max-width: 180px !important;
}

.empty-class {
  width: 165px;
  height: 120px;
  background-color: #EEE;
  border-radius: 10px;
}

.schedule-class__coach-name {
  font-size: 14px;
  line-height: 14px;
}

.monterrey__schedule-class {
  height: 120px;
  width: 165px;
  border-radius: 10px;
}

.monterrey__schedule-class:hover {
  cursor: pointer;
  background-color: rgba(157,51,85, 0.18);
}

@media(max-width: 700px) {
  .schedule__class-types .row{
    justify-content: center;
  }
}