.rewards-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 120px;
  padding-left: 120px;
  padding-top: 20px;
  padding-bottom: 80px;
}

.rewards-video-title {
  font-family: "Futura Std", sans-serif;

  font-size: 45px;
  font-style: italic;
  font-weight: 700;
  line-height: 54px;
  text-align: center;
  color: #2f2f2b;
}

@media (max-width: 767px) {
  .rewards-video {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 400px;
  }

  .rewards-video-title {
    font-size: 30px;
    line-height: 36px;
  }

  .rewards-video img {
    width: 100%;
  }
}
