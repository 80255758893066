:root {
    --dark: #000;
    --light: rgb(239,238,234);
    --primary: rgb(157,51,85);
    --primary-light: rgb(244,184,214);
    --primary-light-transparency: rgb(244, 184, 214, 0.5);
    --secondary: rgb(153,180,210);
    --secondary-light: rgb(211, 223, 237);
    --gray: rgb(198,198,198);
    --dark-gray: rgb(102,100,94);
    --accent: rgb(109,134,180);
    --warning: rgb(237,184,128);
    --accent-light: rgb(205,238,249);
    --danger: rgb(281,83,72);
    --success: rgb(207,222,134);
    --accent-transparency: rgba(117, 138, 187, 0.75);
    --light-blue: rgba(208,224,239,0.5);
    --dark-gray: #5C5954;
    --blue: #92B5D5;
    --menta: rgb(186,204,206);
}

body {
    color: #5C5954;
}

/* Nav */

.nav-item:hover {
    background-color: var(--primary-light);
    border-bottom: 3px solid var(--primary);
    color: var(--primary);
}

.nav-item-active {
    background-color: var(--primary-light);
    border-bottom: 3px solid var(--primary);
    color: var(--primary);
}

/* Background */

.bg-light {
    fill: var(--light) !important;
    background-color: var(--light) !important;
}

.bg-black {
    background-color: #000;
}

.bg-primary {
    fill: var(--primary) !important;
    background-color: var(--primary) !important;
}

.bg-secondary {
    background-color: var(--secondary) !important;
}

.bg-gray {
    background-color: var(--gray) !important;
}

.bg-dark-gray {
    background-color: var(--dark-gray) !important;  
}

.bg-opacity {
    background-color: var(--dark-transparency) !important;
}

.bg-dark {
    background-color: var(--dark) !important;
}

.bg-special {
    background-color: #C9E881 !important;
}

.bg-accent {
    background-color: var(--accent) !important;
    color: white !important;
}

.bg-accent-secondary {
    background-color: var(--accent-secondary);
}

.bg-primary-light {
    fill: var(--primary-light) !important;
    background-color: var(--primary-light);
}

.bg-secondary-light {
    background-color: var(--secondary-light);
}

.bg-danger {
    background-color: var(--danger) !important;
    color: white !important;
}

.bg-success {
    background-color: var(--success) !important;
    color: var(--dark) !important;
}

.bg-gradient {
    background: linear-gradient(60deg, rgba(157,51,85,1) 0%, rgba(109,134,180,1) 52%, rgba(211,223,237,1) 100%) !important; 
}

/* Text */

.text-dark {
    color: var(--dark-gray) !important;
}

.text-accent {
    color: var(--accent);
}

.text-blue {
    color: var(--blue);
}

.text-primary {
    color: var(--primary) !important;
}

.text-primary-light {
    color: var(--primary-light) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

.text-black {
    color: black;
}

/* Forms */

.form-control {
    background-color: var(--light);
}

/* Buttons */

.btn-online {
    background-color: var(--primary-light);
    border-color: transparent;
    color: var(--primary);
    border-width: 0px;
}

.btn-onsite {
    background-color: var(--secondary-light);
    border-color: transparent;
    color: var(--secondary);
    border-width: 0px;
    
}

.btn-pink {
    background-color: var(--primary-light) !important;
    border: 2px solid var(--primary-light) !important;
    color: white;
}

.btn-warning {
    background-color: var(--warning) !important;
    border: 1px solid var(--warning) !important;
}

.btn-success {
    background-color: var(--success) !important;
    color: black;
}

.btn-outline-primary {
    border: 2px solid var(--primary) !important;
    color: var(--primary) !important;
    text-transform: uppercase;
    font-weight: 700;
}

.btn-outline-primary:hover {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    color: white !important;
}

.btn-primary {
    background-color: var(--primary) !important;
    border: 2px solid var(--primary) !important;    
    color: white !important;
    text-transform: uppercase;
    font-weight: 800;
}

.btn-danger {
    background-color: var(--danger) !important;
}

.btn-sucess {
    background-color: var(--success) !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active {
    background-color: var(--primary-light) !important;
    border: 2px solid var(--primary) !important;    
    color: var(--primary) !important;
    text-transform: uppercase;
    font-weight: 800;
}

.btn-secondary {
    border: 2px solid var(--blue) !important;
    background-color: var(--blue) !important;
    color: white;       
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:active {
    background-color: var(--accent);
    border: 2px solid white !important;
    color: white;
    text-transform: uppercase;
    font-weight: 800;
}

.btn-whatsapp {
    background-color: #198754 !important;
    color: white !important;
}

/* Border */

.border-primary {
    border-color: var(--primary) !important;
}

.border-primary-light {
    border-color: var(--primary-light) !important;
}

/* Accordion */

.accordion-button {
    font-family: "Futura Condensed Extra Bold", sans-serif !important;
    color: #5C5954;

}

.accordion-button:not(.collapsed) {
    color: var(--primary) !important;
    background-color: white !important;
}


.accordion-button:focus {
    box-shadow: none !important;
}

/* Misc */

.reply {
    border-left: 5px solid var(--primary);
}

.name-row:focus {
    background-color: var(--light);
}

.black-belt {
    background-color: var(--gray);
}

/* Landing */

.program-meta, .class-type-meta {
    background-color: var(--light-blue);
    color: white;
}

.nutrition-tip-image {
    background-color: var(--light-blue);
}

.program-card:hover .program-meta {
    background-color: var(--primary) !important;
}

.plan-meta {
    background-color: var(--primary);
}

.plan-meta-button {
    background-color: var(--menta) !important;
    border-radius: 150px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    width: 60px;
    height: 60px;
    font-size: 24px;
    position: absolute;
    top: -25px;
    right: 25px;
}

.outline-coach-image {
    border: 1px solid var(--primary) !important;
}

.bg-coach-image {
    background-color: var(--primary) !important;
}

.outline-estreno-image {
    border: 1px solid var(--accent-light) !important;
}

.bg-estreno-image {
    background-color: var(--accent-light) !important;
}