.monterrey-banner {
  display: flex;
  height: 630px;
  padding: 0px !important;
  justify-content: center;
  align-items: center;
}

.monterrey-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.monterrey-banner__title {
  text-align: center;
  font-size: 73px;
  width: 334px;
  position: absolute;
  z-index: 3 ;
  color: white;
}

.monterrey-banner__cta {
  bottom: 170px;
  width: 335px;
}

@media(max-width: 767px) {
  .monterrey-banner {
    height: 315px;
  }


  .monterrey-banner__title {
    font-size: 48px;
    width: 235px;
    top: 55px;
  }
  
  .monterrey-banner__cta {
    bottom: 60px;
    width: 220px;
    font-size: 15px;
  }
}