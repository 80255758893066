.schedule-class {
    border-radius: 10px;
    width: 95%;
}

.schedule-col {
    width: calc(100% / 7);
}

.time-container {
    min-height: 150px;
}

@media(max-width: 767px) {
    .schedule-col {
        width: calc(100% / 7);
        margin-top: 32px;
    }
    .schedule-mobile-container {
        overflow-x: scroll;
    }
    .schedule-mobile-wrapper {
        width: 350%;
    }
}