.monterrey-about {
  padding: 80px 97px 40px 97px;
}

.monterrey-about__title {
  font-size: 55px;
  font-weight: 900;
}

.monterrey-about__desc {
  font-size: 18px;
  font-weight: 500;
}

.features__container {
  max-width: 800px;
} 

.monterrey-about__feature {
  height: 100px;
}

.feature__numb {
  font-size: 42px;
  font-weight: 900;
  color: black;
  line-height: 45px;
  margin-bottom: 0px !important;
}

.feature__name {
  font-size: 21px;
  font-weight: 900;
  color: black;
  margin-bottom: 0px;
}

.feature__desc {
  font-size: 15px;
  color: black;
}

.monterrey-about__btn {
  width: max-content;
}

@media(max-width: 450px) {
  .monterrey-about {
    padding: 20px;
    width: 100vw;
  }

  .monterrey-about__title {
    font-size: 38px;
    text-align: center;
    margin-top: 20px;
    line-height: 40px;
  }

  .monterrey-about__desc {
    text-align: center;
    font-size: 16px;
  }

  .monterrey-about__feature {
    flex-direction: column;
    text-align: center;
    height: 80px;
    margin-bottom: 15px !important;
    align-items: flex-start;
  }

  .feature__numb {
    font-size: 20px;
    margin-bottom: 10px !important;
    line-height: 20px;
  }

  .feature__name {
    font-size: 13px;
    line-height: 13px;
  }

  .feature__desc {
    font-size: 12px;
    line-height: 12px;
  }

  .monterrey-about__btn {
    width: 100%;
  }
}