.monterrey-landing__container {
  padding: 30px;
  height: 990px;
  padding-bottom: 0;
}

.navbar-container {
  padding-left: 120px;
  padding-right: 120px;
}

.container__content-container {
  z-index: 3;
  top: 30px;
  width: calc(100% - 60px);
  margin: 0 30px;
  padding: 0 68.5px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.rewards-navbar {
  margin-top: 55px;
  z-index: 3;
  position: absolute;
}

.rewards-navbar .nav-item:hover {
  background-color: unset !important;
  border: unset !important;
  color: #9589F7 !important;
}

.rewards-navbar .nav-item-active {
  color: #9589F7 !important;
  background-color: unset !important;
  border: unset !important;
}

.rewards-navbar .nav-item-active .nav-link {
  color: #9589F7 !important;
}

.rewards-navbar .navbar-cta:hover {
  color: white !important;
}

.rewards-navbar .nav-link:hover {
  color: #9589F7 !important;
}

.rewards-navbar .navbar-toggler {
  font-size: 15px !important;
  padding: 0;
  width: 45px !important;
  height: 45px !important;
  background-color: white !important;
}

.description__bottom {
  position: absolute;
  bottom: 30px;
  margin: 0 auto;
  right: 0;
  left: 0;
}

@media(max-width: 767px) {
  .rewards-navbar {
    z-index: 3;
    width: calc(100% - 30px) !important;
    position: absolute;
  }
}

@media(max-width: 450px) {
  .monterrey-landing__container {
    padding: 20px;
    height: 530px;
  }

  .rewards-navbar .navbar-collapse {
    padding: 10px 0;
    background-color: white !important;
  }

  .container__content-container .navbar-nav .nav-item .nav-link {
    font-size: 18px;
    color: var(--bs-nav-link-color) !important;
  }

  .container__content-container {
    width: calc(100% - 30px);
    padding: 0 15px;
    margin: 0 auto;
  }

  .navbar-brand img {
    width: 48px !important;
    height: 48px !important;
  }

  .navbar-toggler {
    font-size: 15px !important;
    padding: 0;
    width: 45px !important;
    height: 45px !important;
    background-color: white !important;
  }

  .head__desc {
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .head__app-name {
    font-size: 37px !important;
    line-height: 37px !important;
    letter-spacing: normal !important;
  }

  .head__city {
    font-size: 20px !important;
    line-height: 20px !important;
    letter-spacing: normal !important;
  }

  .content__title {
    font-size: 18px !important;
    line-height: 18px !important;

  }

  .content__text {
    font-size: 14px;
    line-height: 18px !important;
    width: 230px;
  }

  .content-container__description {
    margin-top: 100px !important;
  }

  .description__bottom {
    font-size: 15px !important;
    bottom: -70px;

  }

  .navbar-cta__container {
    position: relative !important;
  }

  .navbar-cta {
    height: max-content !important;
    font-size: 16px !important;
  }
}

.navbar-nav .nav-item .nav-link {
  font-size: 18px;
  color: #2F2F2B;
}

.navbar-nav .navbar-cta {
  border-radius: 40px;
  width: 170px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  color: white;
  border: none;
  /* background: transparent; */
}

.content-container__description {
  margin-top: 130px;
}

.head__desc {
  width: 750px;
  font-size: 32px;
  color: white;
  font-weight: bolder;
  line-height: 40px
}

.head__app-name {
  width: 100%;
  font-size: 155px;
  font-style: normal;
  font-weight: bolder;
  letter-spacing: -6px;
  font-stretch: ultra-condensed;
  text-wrap: nowrap;
  color: white;
  line-height: 180px;
}

.text-gradient {
  background-image: linear-gradient(90deg, #9589F7 0%, #D1DFEF 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  /* -webkit-text-stroke: 0.8px white; Ajusta el tamaño del borde aquí */
}

.head__city {
  font-size: 36px;
  align-self: flex-start;
  font-weight: bolder;
}

.description__content {
  width: 400px;
}

.description__content .content__title {
  color: white;
  font-size: 36px;
  font-weight: bold;
}

.description__content .content__text {
  color: white;
}

.container__video {
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}