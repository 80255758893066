

.packages-carousel__container {
  padding-left: 97px;
}

.packages-carousel__bottom {
  font-size: 12px;
  align-self: flex-end;
}

@media(max-width: 450px) {
  .packages-carousel__title {
    margin-bottom: 10px;
  }

  .packages-carousel__subtitle {
    font-size: 16px;
    margin-bottom: 25px;
  }

  .packages-carousel__container {
    padding: 0 8px
  }

  .packages-carousel__bottom {
    font-size: 12px;
  }
}