.rewards-features {
  height: 850px;
  padding: 50px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, #968af8 0%, #968af8 78.5%, #ffffff 100%);
}

.features__title {
  font-family: "Futura Std", sans-serif;
  font-size: 42px;
  font-style: italic;
  font-weight: 700;
  line-height: 50.4px;
  text-align: center;
  color: #ffffff;
}

.features__desc {
  font-family: "Futura Std", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
}

.rewards-features .card {
  width: 390px;
  height: 520px;
  padding: 24px !important;
  border-radius: 16px;
  border: 1px white solid !important;
  background-color: transparent;
  opacity: 0px;
}

.feat-title {
  font-family: "Futura Std", sans-serif;
  font-size: 30px;
  font-weight: 750;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
}

.feat-image {
  border-radius: 16px;
  margin-bottom: 10px;
}

.feat-desc {
  font-family: "Futura Std", sans-serif;
  font-size: 19px;
  font-weight: 500;
  line-height: 22.8px;
  text-align: left;
}

.rewards-text-gradient {
  background-image: linear-gradient(
    180deg,
    #968af8 0%,
    #cedaf0 29.5%,
    #b8bbf3 45%,
    #968af8 69.5%,
    #cedaf0 100%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.feat-btn {
  border-radius: 40px;
  background-color: white;
  width: 230px;
  height: 50px;
  font-family: "Futura Std", sans-serif;
  font-size: 19.05px;
  font-weight: 750;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 767px) {
  .rewards-features {
    padding: 20px 30px;
    height: 2050px;
  }
  .feat-desc {
    font-size: 16px;
  }

}
