.rewards-brands__features {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  width: 100vw;
  background-color: transparent;
  height: 100vh;
}

.rewards-brands__backdrop {
  background-color: #000; /* Negro */
  opacity: 0.5; /* Transparencia */
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.carousel__brands-features {
  position: absolute;
  width: 100%;
  height: 725px;
  top: calc((100vh - 725px) / 2);
  background-color: transparent;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}
.brands-features__viewport {
  overflow: hidden;
}
.brands-features__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
}
.brands-features__slide {
  width: 100%;
  height: 725px;
  padding-left: calc((100vw - 1040px) / 2) !important;
  padding-right: calc((100vw - 1040px) / 2);
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.slide__container {
  border-radius: 8px;
  border: 1px #7550FE solid;
  padding: 30px 55px 30px 55px;
}
.brands-features__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
}
.brands-features__controls {
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
}
.brands-features__buttons {
  display: flex;
  width: 1040px;
  justify-content: space-between;
  position: absolute;
  top: calc((725px - 3.6rem) / 2);
  right: 0;
  left: 0;
  margin: 0 auto;
  align-items: center;
}
.brands-features__buttons .embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.brands-features__buttons .embla__button:disabled {
  color: var(--detail-high-contrast);
}
.brands-features__buttons .embla__button__svg {
  width: 35%;
  height: 35%;
  color: #A9A9A9;
}
.brands-features__dots {
  display: flex;
  flex-wrap: wrap;
  background-color: #B8B8B8;
  border-radius: 16px;
  height: 21px;
  width: 93px;
  justify-content: space-evenly;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.brands-features__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: #dddddd;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 7px;
  height: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.brands-features__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.brands-features__dot--selected {
  background-color: #FFF;
}
.brands-features__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}

@media (max-width: 767px) {
  .brands-features__slide {
    width: 1040px;
    overflow-x: auto;
    height: 725px;
    padding-left: calc((100vw - 1040px) / 2) !important;
    padding-right: calc((100vw - 1040px) / 2);
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
  }

  .slide__container {
    width: 1040px;
    border-radius: 8px;
  }

}

